#debug-interface {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  color: white;
}

#readout {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 99vw;
  resize: none;
  font-size: medium;
  color: black;
}

.clickable {
  pointer-events: all;
}
